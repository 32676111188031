























































































import { defineComponent, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import auth from '@/composables/auth'
import * as sso from '@/composables/sso'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  components: {
    CustomLink,
  },
  name: 'commune-signin',
  setup(_props, ctx) {
    const { VUE_APP_TASK } = process.env
    const content = ref<Record<string, any>>()
    const { lang } = ctx.root.$route.params
    const communeState = {
      ...useState('commune', ['i18n']),
    }
    const communeName = window.localStorage.getItem('communeName')

    const signIn = () => {
      const cb = '/dashboard/'
      if (VUE_APP_TASK === 'serve') {
        sso.authorize(cb)
      } else {
        sso.authorize(`/${lang}${cb}`)
      }
    }
    const { signOut } = auth

    return {
      content,
      communeI18n: communeState.i18n,
      isLogged: auth.isLogged,
      signIn,
      signOut,
      lang,
      communeName,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
